import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Resources from "../components/projects/Resources"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        resources: Array<{
          title: string
          description: string
          url: string
          image: string
        }>
        features: Array<{
          title: string
          description: string
        }>
      }
    }
  }
}

// markup
const ReactiveTemplate = (props: Props) => {
  const resources = props.data.page.frontmatter.resources
  const features = props.data.page.frontmatter.features
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={`event-driven reactive`}
    >
      <div className="extra-0">
        <img src={`/img/extra/reactive-6.svg`} alt="" />
      </div>
      <div className="container">
        <div className="hero-reactive content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown links-animate"}
          ></HTMLContent>
          <img
            className="image img-light"
            src={`/img/extra/reactive-2.svg`}
            alt=""
          />
          <img
            className="image img-dark"
            src={`/img/extra/reactive-2-dark.svg`}
            alt=""
          />
        </div>

        <div className="markdown links-animate content">
          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[0].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent content={features[0].description}></HTMLContent>
              <div className="pt-5">
                <a href="https://projectreactor.io/">Learn more</a>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[1].title}
              </h1>
            </div>
            <div className="column is-8">
              <div className="columns is-mobile">
                <div className="column is-3">
                  <img
                    className="img-light"
                    src={`/img/extra/reactive-4.svg`}
                    alt=""
                    width={160}
                  />
                  <img
                    className="img-dark"
                    src={`/img/extra/reactive-4-dark.svg`}
                    alt=""
                    width={160}
                  />
                </div>
                <div className="column is-9">
                  <HTMLContent
                    className=""
                    content={features[1].description}
                  ></HTMLContent>
                </div>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[2].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent content={features[2].description}></HTMLContent>
              <div className="pt-5 mt-3">
                <img
                  src={`/img/extra/reactive-5.svg`}
                  className="img-light"
                  alt=""
                  style={{ maxWidth: 800 }}
                />
                <img
                  src={`/img/extra/reactive-5-dark.svg`}
                  className="img-dark"
                  alt=""
                  style={{ maxWidth: 800 }}
                />
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 is-size-3-mobile has-text-weight-medium m-0 p-0">
                {features[3].title}
              </h1>
            </div>
            <div className="column is-6">
              <HTMLContent content={features[3].description}></HTMLContent>
            </div>
          </article>
        </div>
        <div className="has-background-light mt-6 p-6 has-text-centered">
          <div className="py-5">
            <h2 className="is-size-2 is-size-3-mobile has-text-weight-medium pb-6">
              Ready to get started?
            </h2>
            <Link className="button is-spring" to={`/quickstart`}>
              TRY THIS TUTORIAL
            </Link>
          </div>
        </div>

        <Resources resources={resources} />
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default ReactiveTemplate

// graphQL queries
export const pageQuery = graphql`
  query ReactiveTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
        resources {
          title
          description
          image
          url
          video
        }
        features {
          title
          description
        }
      }
    }
  }
`
